import React, { useState, useEffect, useContext, useCallback } from "react"
import clsx from "clsx"
import formatValue from "../utils/formatValue"
import Skeleton from '@material-ui/lab/Skeleton'
import { Box, CircularProgress, useTheme, Checkbox, Chip, Box as MuiBox } from "@material-ui/core"
import {
  List,
  Card,
  CardTitle,
  CardSubtitle,
  CardBody,
  CardMedia,
  RichText,
  Text,
  CardFooter,
  FileListView,
} from "."
import useStyles from './styles'
import { useAppContext } from "App"
import { getStyleProgressStatus } from "shared/utils"
import moment from "moment"
import activityTypes from "fixtures/activityTypes"
import {
  DirectionsRun as ActivityIcon,
} from '@material-ui/icons'

export const CardList = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const appContext = useAppContext()
  const { fields, skeleton = 0, renderCardItem, itemListRef, items, selectedIndex, onCardClick, variant, isFetching, ...listProps } = props
  // destructure selection props
  const { showCheckBox, onRowSelect, isRowSelected } = props

  const onCardItemClick = useCallback((item) => () => onCardClick && onCardClick(item),
    [onCardClick])

  const renderFieldComponent = (item, field) => {
    if(field.component == 'ActivityInfo'){
      const activityInfoList = [
        {
          key: 'activities_count',
          icon: ActivityIcon
        },
        {
          key: 'comments_count',
          icon: (activityTypes['chat'] || {}).icon
        }
      ]

      return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
          {
            activityInfoList
            // ?.filter(f => typeof item[f.key] !== 'undefined')
            ?.filter(f => item[f.key])
            ?.map(act => (
              <div className={classes.activityItem}>
                {React.createElement(
                  act.icon, {
                  style: { color: '#aaa', fontSize: '15px', marginRight: '2px' }
                }
                )}
                <Text variant="subtitle2">
                  {item[act.key]}
                </Text>
              </div>
            ))
          }
        </div>
      )
    }

    return ''
  }

  const getUserName = (user) => {
    if(!user) return ''
    
    let name = ''
    if (user) {
      if (user?.first_name || user?.last_name) {
        name = `${user?.first_name || ""} ${user?.last_name || ""}`.trim()
      }
    }

    if(!name){
      name = `Contact ${user?.uid % 9999}`
    }

    return name
  }

  const getFieldType = (type) => {
    const types = ['priority', 'resolution', 'issue_type']
    if (types.some(t => t === type))
      return 'option'

    return type
  }

  const getFieldData = (item, field) => {
    let fieldValue = null

    if (!field)
      return null
    else if (field.render)
      fieldValue = field.render(item, field)
    else if(field.component)
      fieldValue = renderFieldComponent(item, field)
    else if(getFieldType(field.field_type) == 'contact') {
      fieldValue = getUserName(item[field.key])
    }
    else if(getFieldType(field.field_type) == 'company')
      fieldValue = item[field.key]?.name
    else if(field?.field_type == 'company_contact')
      fieldValue = `${getUserName(item[field.key]?.contact)} ${item[field.key]?.company ? `from ${item[field.key]?.company?.name}` : ''}`
    else if(getFieldType(field.field_type) == 'option'){
      if (field?.multiple_value) {
        fieldValue = item[field.key]?.map(field => <Chip label={field.name} style={{margin: '3px 5px 3px 0'}}/>)
      } else fieldValue = item[field.key]?.name
    }
    else if(getFieldType(field.field_type) == 'custom' && typeof item?.[field.key] == 'object'){
      fieldValue = item?.[field.key]?.name || item?.[field.key]?.title || item?.[field.key]?.value || ''
    }
    else if(getFieldType(field.field_type) == 'user'){
      if (item[field.key]) {
        if (item[field.key].uid == appContext.userInfo?.uid)
          fieldValue = "Me"
        else if (Array.isArray(item[field.key]))
          fieldValue = item[field.key].map(i => `${i.first_name || ''} ${i.last_name || ''}`).join(', ')
        else
          fieldValue = `${item[field.key].first_name || ''} ${item[field.key].last_name || ''}`
      } else {
        if (field.key === 'assigned_to') {
          /** Assigned to all agent */
          fieldValue = "Unassigned"
        } else {
          /** Hide if empty */
          fieldValue = null
        }
      }
    }
    else if(getFieldType(field.field_type) == 'status'){
      fieldValue = (
        <div style={{ display: 'flex' }}>
          <MuiBox>
            <Text
              variant="body2"
              noWrap={true}
              textCustomProps={getStyleProgressStatus(item[field.key])}
            >
              {item[field.key]?.name}
            </Text>
          </MuiBox>
        </div>
      )
    }
    else if(getFieldType(field.field_type) == 'float')
      fieldValue = item[field.key] ? new Intl.NumberFormat('ID').format(item[field.key]) : ''
    else if(
      getFieldType(field.field_type) == 'date' ||
      (moment(item[field.key], moment.ISO_8601, true).isValid() && !field.format) // check value is date without format
    )
      fieldValue = formatValue(item[field.key], 'datetime|DD MMM yyy')
    else if(getFieldType(field.field_type) == 'media')
      fieldValue = item[field.key] ? (
        <FileListView value={item[field.key]} useSmallThumbs disableBorder />
      ) : <></>
    else if(getFieldType(field.field_type) == 'time') {
      fieldValue = (
        <div style={{ display: 'flex' }}>
          <Text variant="body2" noWrap={true} textCustomProps={field?.text_props || {}}>
            {moment(item[field.key]).format('HH:mm')}
          </Text>
        </div>
      )
    } else if(getFieldType(field.field_type) == 'time_range_start') {
      const timeRangeEndField = fields?.body?.find(f => f?.field_type === 'time_range_end')?.key
      fieldValue = (
        <div style={{ display: 'flex' }}>
          <Text variant="body2" noWrap={true} textCustomProps={field?.text_props || {}}>
            {moment(item[field.key]).format('HH:mm')}{timeRangeEndField ? ` - ${moment(item[timeRangeEndField]).format('HH:mm')}` : ''}
          </Text>
        </div>
      )
    } else if(getFieldType(field.field_type) == 'time_range_end') {
      /** hide time_range_end */
      fieldValue = null
    } else if(getFieldType(field.field_type) == 'issue_aggregate')
      // hardcode for now
      // possibly went unused
      fieldValue = '5'
    else fieldValue = formatValue(item[field.key], field.format)

    return (field?.label && fieldValue) ? (
        <div>
          <Text variant="body2">{field.label}: {fieldValue}</Text>
        </div> 
      ) : fieldValue
  }

  // const isIssueDone = (item) => item?.status?.progress >= 90 && item?.status?.progress <= 100
  const isIssueDone = useCallback((item) => item?.status?.progress >= 90 && item?.status?.progress <= 100, [items])

  function renderCardSkeleton(index) {
    if (renderCardItem)
      return renderCardItem(undefined, index, { skeleton: 1 })
    else
      return (
        <Card variant={variant} skeleton={1}>
          {fields.title &&
            <CardTitle
              primary={<Skeleton />}
            />
          }
          {fields.subtitle &&
            <CardSubtitle
              primary={<Skeleton />}
            />
          }
          {fields.body &&
            <CardBody
              rows={fields.body?.map(field => <Skeleton />)}
            />
          }
        </Card>
      )
  }

  function renderCard(item, index) {
    const draggableId = listProps.droppableId ? `${listProps.droppableId}-${index}` : undefined
    if (renderCardItem)
      return renderCardItem(item, index, {
        variant: variant,
        selected: index == selectedIndex,
        index: index,
        onClick: onCardItemClick(item),
        draggableId,
      })
    else
      return (
        <div className={classes.selectableCardWrapper}>
          {showCheckBox && (
            <div className={classes.listCheckbox}>
              <Checkbox
                checked={isRowSelected && isRowSelected(item, index)}
                onChange={(event) => onRowSelect && onRowSelect(item, index)}
              />
            </div>
          )}
          <Card
            variant={variant} onClick={onCardItemClick(item)}
            key={index}
            selected={index == selectedIndex}
            index={index}
            draggableId={draggableId}
          >
            {fields.media &&
              <CardMedia
                selected={index == selectedIndex}
                media={getFieldData(item, fields.media)}
                width={fields.media.width}
                aspectRatio={fields.media.aspectRatio}
              />
            }
            {fields.title &&
              <CardTitle
                selected={index == selectedIndex}
                textPrimaryColor={isIssueDone(item) ? "textSecondary" : undefined}
                primary={getFieldData(item, fields.title.primary)}
                secondary={getFieldData(item, fields.title.secondary)}
              />
            }
            {fields.subtitle &&
              <CardSubtitle
                selected={index == selectedIndex}
                maxLine={3}
                primary={getFieldData(item, fields.subtitle.primary)}
                textPrimaryColor={isIssueDone(item) ? "textSecondary" : undefined}
                secondary={getFieldData(item, fields.subtitle.secondary)}
              />
            }
            {fields.body &&
              <CardBody
                selected={index == selectedIndex}
                rows={fields.body?.map(field => getFieldData(item, field))}
              />
            }
            {fields.footer && Object.keys(fields.footer).length > 0 &&
              <CardFooter
                selected={index == selectedIndex}
                maxLine={3}
                primary={getFieldData(item, fields.footer.primary)}
                textPrimaryColor={isIssueDone(item) ? "textSecondary" : undefined}
                secondary={getFieldData(item, fields.footer.secondary)}
              />
            }
          </Card>
        </div>
      )
  }

  return (
    <List variant={variant} {...listProps}>
      {skeleton ?
        [...Array(skeleton)].map((_, index) => renderCardSkeleton(index))
        : items
          .map((item, index) => (
            <div ref={ref => {if(itemListRef) itemListRef.current[index] = ref}}>
              {renderCard(item, index)}
            </div>
          ))
          .reduce((p, n) => p ?
            [...p, <div className={variant === "card" && classes.cardSeparator} />, n] :
            [n], null)
      }

      {isFetching &&
        <Box display='flex' justifyContent='center' style={{ padding: theme.spacing(2) }}>
          <CircularProgress size='1.5rem' disableShrink={true} />
        </Box>
      }
    </List>
  )
}

export default CardList