export const findKeyPairs = (fields) => {
  const prefixPatterns = { start: "start_", end: "end_" }
  const suffixPatterns = { start: "_from", end: "_to" }

  const groupedKeys = {}

  fields.forEach((f) => {
    const fieldKey = f.field.key?.replace(/-/g, '_')
    const startMatch = fieldKey.match(new RegExp(`^${prefixPatterns.start}(.+)`))
    const endMatch = fieldKey.match(new RegExp(`^${prefixPatterns.end}(.+)`))
    const suffixStartMatch = fieldKey.match(new RegExp(`(.+)${suffixPatterns.start}$`))
    const suffixEndMatch = fieldKey.match(new RegExp(`(.+)${suffixPatterns.end}$`))

    if (startMatch) {
      const baseKey = startMatch[1]
      groupedKeys[baseKey] = groupedKeys[baseKey] || {}
      groupedKeys[baseKey].startKey = fieldKey
      groupedKeys[baseKey].startName = f.field.name
      groupedKeys[baseKey].fieldType = f.field.field_type
    } else if (endMatch) {
      const baseKey = endMatch[1]
      groupedKeys[baseKey] = groupedKeys[baseKey] || {}
      groupedKeys[baseKey].endKey = fieldKey
      groupedKeys[baseKey].endName = f.field.name
      groupedKeys[baseKey].fieldType = f.field.field_type
    } else if (suffixStartMatch) {
      const baseKey = suffixStartMatch[1]
      groupedKeys[baseKey] = groupedKeys[baseKey] || {}
      groupedKeys[baseKey].startKey = fieldKey
      groupedKeys[baseKey].startName = f.field.name
      groupedKeys[baseKey].fieldType = f.field.field_type
    } else if (suffixEndMatch) {
      const baseKey = suffixEndMatch[1]
      groupedKeys[baseKey] = groupedKeys[baseKey] || {}
      groupedKeys[baseKey].endKey = fieldKey
      groupedKeys[baseKey].endName = f.field.name
      groupedKeys[baseKey].fieldType = f.field.field_type
    }
  })

  const keyPairs = Object.entries(groupedKeys)
    .filter(([_, pair]) => pair.startKey && pair.endKey)
    .map(([baseKey, pair]) => ({
      baseKey,
      startKey: pair.startKey,
      endKey: pair.endKey,
      startName: pair.startName,
      endName: pair.endName,
      fieldType: pair.fieldType,
    }))

  return keyPairs
}
