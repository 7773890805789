import React, { useState, useEffect, useRef, useContext, useCallback, Children } from 'react'
import { useLocation } from 'react-router-dom'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import { Text, Button } from 'shared/ui'
import { useMediaQuery, useTheme, CircularProgress } from '@material-ui/core'
import { getStatusColor } from 'shared/utils/getStyleProgressStatus'
import qs from 'query-string'
import moment from 'moment'
import useStyles from './styles'

import 'react-big-calendar/lib/css/react-big-calendar.css'

const localizer = momentLocalizer(moment)

export const DateCellWrapper = ({ children, value }) => {
  const location = useLocation()
  const queryParams = qs.parse(location.search)
  const isSelected = queryParams?.date === moment(value).format('YYYY-MM-DD')

  const dayCellDate = moment(value)
  const rangeAreaDate = moment(queryParams?.calendar_date_from, 'YYYY-MM-DD')
  const rangeMonth = rangeAreaDate.month()
  const rangeYear = rangeAreaDate.year()

  const isWithinSameMonth = dayCellDate.month() === rangeMonth && dayCellDate.year() === rangeYear

  return React.cloneElement(Children.only(children), {
    style: {
      ...children.style,
      backgroundColor: isSelected ? 'rgba(255, 111, 0, 0.1)' : !isWithinSameMonth ? '#F0F0F0' : 'white',
    },
  })
}

/** custom event render component */
export const EventView = ({ event }) => {
  const classes = useStyles()
  return (
    <div className={classes.calendarEventView}>
      <div
        className={classes.calendarEventLeftAccent}
        style={{ backgroundColor: event.backgroundColor }}
      />
      <Text variant="body2" style={{ fontSize: '0.8rem' }}>
        {event.title}
      </Text>
    </div>
  )
}

/** main CalendarView component */
export const CalendarView = (props) => {
  const {
    items = [], calendarFields, isFetching, onCardClick, containerRef,
    onCalendarDayClick, onCalendarMonthChange, filterData
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  const innerContainerRef = useRef()
  const clickRef = useRef(null)
  const location = useLocation()
  const queryParams = qs.parse(location.search)

  const [view, setView] = useState(smDown ? Views.DAY : Views.MONTH)
  const [currentMonth, setCurrentMonth] = useState(new Date())
  const [isHeightSet, setIsHeightSet] = useState(false)

  const handleNavigate = (date) => {
    setCurrentMonth(date)
  }

  const getFirstAndLastDates = (currentMonth) => {
    const firstDate = moment(currentMonth).startOf('month').format('YYYY-MM-DD')
    const lastDate = moment(currentMonth).endOf('month').format('YYYY-MM-DD')

    return { firstDate, lastDate };
  }

  const calculateRemainingHeight = () => {
    if (containerRef?.current && innerContainerRef.current) {
      const parentHeight = containerRef.current.offsetHeight

      const siblingsHeight = Array.from(containerRef.current.children)
        .filter((child) => child !== innerContainerRef.current)
        .reduce((total, sibling) => total + sibling.offsetHeight, 0)

      const remainingHeight = parentHeight - siblingsHeight
      // innerContainerRef.current.style.height = `${remainingHeight}px`
      innerContainerRef.current.style.height = `${remainingHeight - 32}px`
      if (!isHeightSet) setIsHeightSet(true)

      if (smDown) {
        const parentRect = containerRef.current.getBoundingClientRect()
        const childRect = innerContainerRef.current.getBoundingClientRect()
        const gap = parentRect.bottom - childRect.bottom
        innerContainerRef.current.style.height = `${remainingHeight + (gap * 0.75)}px`
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      calculateRemainingHeight()
    }, 50)
    window.addEventListener("resize", calculateRemainingHeight)

    return () => {
      window.removeEventListener("resize", calculateRemainingHeight)

      /**
       * What Is This?
       * This is to prevent a memory leak, in the off chance that you
       * teardown your interface prior to the timed method being called.
       * 
       * copied from the official docs:
       * https://jquense.github.io/react-big-calendar/examples/index.html?path=/docs/props--on-select-event
       */
      window.clearTimeout(clickRef?.current)
    }
  }, [])

  /** handle first occurence */
  useEffect(() => {
    if (!filterData?.calendar_date_from && !filterData?.calendar_date_to) {
      const { firstDate, lastDate } = getFirstAndLastDates(currentMonth)
      if (onCalendarMonthChange) {
        onCalendarMonthChange(firstDate, lastDate)
      }
    }
  }, [filterData])

  /** handle later occurences */
  useEffect(() => {
    const { firstDate, lastDate } = getFirstAndLastDates(currentMonth)
    if (onCalendarMonthChange) {
      onCalendarMonthChange(firstDate, lastDate)
    }
  }, [currentMonth])

  useEffect(() => {
    if (smDown) {
      calculateRemainingHeight()

      if (view !== Views.DAY) {
        setView(Views.DAY)
      }
    }
  }, [smDown])

  const onSelectEvent = useCallback((calEvent) => {
    /**
     * Here we are waiting 250 milliseconds (use what you want) prior to firing
     * our method. Why? Because both 'click' and 'doubleClick'
     * would fire, in the event of a 'doubleClick'. By doing
     * this, the 'click' handler is overridden by the 'doubleClick'
     * action.
     * 
     * copied from the official docs:
     * https://jquense.github.io/react-big-calendar/examples/index.html?path=/docs/props--on-select-event
     * 
     * also applies to onSelectSlot below
     */
    window.clearTimeout(clickRef?.current)
    clickRef.current = window.setTimeout(() => {
      if (onCardClick) onCardClick(calEvent?.data)
    }, 250)
  }, [queryParams])

  /** handle on click empty date area */
  const onSelectSlot = useCallback((slotInfo) => {
    window.clearTimeout(clickRef?.current)
    clickRef.current = window.setTimeout(() => {
      if (onCalendarDayClick) onCalendarDayClick(slotInfo)
    }, 250)
  }, [queryParams])

  const onShowMore = useCallback(
    (events, date) => {
      if (onCalendarDayClick) onCalendarDayClick({
        slots: [date]
      })
    },
    [queryParams]
  )

  /** handle on click date header */
  const onDrillDown = useCallback((date) => {
    if (onCalendarDayClick) onCalendarDayClick({
      slots: [date]
    })
  }, [queryParams])

  const onView = useCallback((newView) => setView(newView), [setView])

  const calendarEvents = items?.map(item => {
    const { progressBackgroundColor, progressTextColor } = getStatusColor(item?.status)
    return {
      id: item?.uid,
      title: calendarFields?.item?.primary ? item?.[calendarFields?.item?.primary] : item?.name,
      start: item?.calendar_date,
      end: item?.calendar_date,
      backgroundColor: progressBackgroundColor,
      textColor: progressTextColor,
      data: { ...item }
    }
  })

  const eventPropGetter = (event, start, end, isSelected) => {
    return {
      className: isSelected ? 'custom-selected-event' : 'custom-event',
      style: {
        backgroundColor: isSelected ? event.backgroundColor : 'transparent',
        color: isSelected ? event.textColor : 'grey',
        borderRadius: '3px',
        cursor: 'pointer',
      }
    }
  }

  const renderToolbar = ({ label, onNavigate }) => {
    return (
      <div className={classes.calendarToolbar}>
        <div className={classes.calendarMonthLabel}>
          <Text variant="h5">
            <strong>{label}</strong>
          </Text>
          {isFetching && (
            <CircularProgress color="secondary" size={16} />
          )}
        </div>
        <div className={classes.calendarButtonRow}>
          <Button
            size="normal"
            variant="text"
            color="primary"
            kind="arrow-left"
            label="Back"
            onClick={() => onNavigate('PREV')}
          />
          <Button
            size="normal"
            variant="text"
            color="primary"
            label="Today"
            onClick={() => onNavigate('TODAY')}
          />
          <Button
            size="normal"
            variant="text"
            color="primary"
            kind="arrow-right"
            label="Next"
            onClick={() => onNavigate('NEXT')}
            useRightIcon
          />
        </div>
      </div>
    )
  }

  return (
    <div ref={innerContainerRef}>
      {isHeightSet && (
        <Calendar
          views={['month']}
          localizer={localizer}
          events={calendarEvents}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={onSelectEvent}
          onSelectSlot={onSelectSlot}
          onShowMore={onShowMore}
          onView={onView}
          onDrillDown={onDrillDown}
          view={view}
          selectable
          eventPropGetter={eventPropGetter}
          onNavigate={handleNavigate}
          messages={{
            showMore: () => "View all"
          }}
          components={{
            toolbar: renderToolbar,
            event: EventView,
            dateCellWrapper: DateCellWrapper
          }}
        />
      )}
    </div>
  )
}

export default CalendarView